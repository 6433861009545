var generic = generic || {};
var site = site || {};

(function ($, Drupal) {
  var isMobileWidth = true;
  // Modernizr touch + not firefox
  var isTouchDevice = Modernizr.touch && navigator.userAgent.toLowerCase().indexOf('firefox') <= -1;

  function Navigation($elem) {
    var self = this;
    var $categories = $('.js-nav-category-trigger', $elem);

    self.$menu = $elem;
    self.activeCategoryObject;

    // Loop through and create each category
    self.attachCategories($categories.filter('.js-nav-category-trigger--top'));
    self.attachSubCategories($categories.filter(':not(.js-nav-category-trigger--top)'));

    $('.js-site-header-menu-trigger', self.$nav)
      .off('click.triggerGnav')
      .on('click.triggerGnav', function (event) {
        event.preventDefault();
        $('body').toggleClass('site-header-menu--opened');
      });

    $('.js-header-search-close', self.$nav)
      .off('click.searchClose')
      .on('click.searchClose', function (event) {
        event.preventDefault();
        $('.typeahead-wrapper', self.$nav).addClass('hidden');
      });

    $(window)
      .off('resize.attachCategories')
      .on(
        'resize.attachCategories',
        _.debounce(function () {
          self.attachCategories($categories.filter('.js-nav-category-trigger--top'));
          self.attachSubCategories($categories.filter(':not(.js-nav-category-trigger--top)'));
        }, 100)
      );
  }

  Navigation.prototype.attachCategories = function ($categories) {
    var self = this;

    $categories.each(function (index) {
      var activateEvent = isMobileWidth || isTouchDevice ? 'click.gnav' : 'mouseenter.gnav';
      // Events to off if we switch breakpoints
      var deactivateEvent = 'mouseenter.gnav click.gnav';
      var $category = $categories.eq(index);
      var categoryObject = new NavigationCategory($category, self.$menu);
      var $closeCategory = categoryObject.$categoryDropdown.find('.js-gnav-section-close');

      // If no links, it's only tout images which means we reduce the spacing
      if (!categoryObject.$categoryDropdown.find('.js-content-block-link, .js-menu-shop-all').length) {
        categoryObject.$categoryDropdown.addClass('no-extra-padding');
      }
      $category.off(deactivateEvent).on(activateEvent, function (event) {
        var eventType = event.type;

        if (
          (isMobileWidth || isTouchDevice) &&
          eventType.includes('click') &&
          $category.closest('.gnav-section').hasClass('gnav-section--has-subcategory')
        ) {
          event.preventDefault();
        }
        self.activateCategory(categoryObject);
      });
      categoryObject.$categoryDropdown.once().on('mouseleave clickoutside', function (event) {
        if (!isMobileWidth) {
          if (
            event.relatedTarget === categoryObject.$category[0] ||
            categoryObject.$categoryDropdown.find(event.target).length ||
            $(event.target).hasClass('js-search-close-icon-wrapper')
          ) {
            return;
          }
          self.deactivateCategory();
        }
      });
      $category.off('keypress.gnav').on('keypress.gnav', function (event) {
        if (site.getKeycode(event) === 13) {
          event.preventDefault();
          self.activateCategory(categoryObject);
        }
      });
      $closeCategory.off('click.gnavClose').on('click.gnavClose', function (event) {
        event.preventDefault();
        self.deactivateCategory();
      });
    });
  };

  Navigation.prototype.toggleSubCategory = function ($subcategoryTrigger) {
    var $subCategory = $subcategoryTrigger.closest('.js-menu-item-category');
    var $subCategoryDropdown = $subCategory.find('.js-nav-category-trigger-content');

    $subCategoryDropdown.toggleClass('active-dropdown');
    if ($subCategoryDropdown.hasClass('active-dropdown')) {
      $subCategoryDropdown.stop(true, true).slideDown();
    } else {
      $subCategoryDropdown.stop(true, true).slideUp();
    }
    $subCategory.toggleClass('active', $subCategoryDropdown.hasClass('active-dropdown'));
  };

  Navigation.prototype.attachSubCategories = function ($subCategories) {
    if (!isMobileWidth) {
      return;
    }
    var self = this;

    $subCategories.each(function (index) {
      var $subCategory = $subCategories.eq(index);

      $subCategory.off('click.subnav').on('click.subnav', function (event) {
        event.preventDefault();
        self.toggleSubCategory($(this));
      });
      $subCategory.off('keypress.subnav').on('keypress.subnav', function (event) {
        if (site.getKeycode(event) === 13) {
          event.preventDefault();
          self.toggleSubCategory($(this));
        }
      });
      if ($subCategory.closest('.js-menu-item-category').hasClass('js-preactivated')) {
        self.toggleSubCategory($subCategory);
      }
    });
  };

  Navigation.prototype.activateCategory = function (categoryObject) {
    if (this.activeCategoryObject) {
      this.activeCategoryObject.deactivate();
    }
    if (categoryObject === this.activeCategoryObject) {
      this.activeCategoryObject = false;
    } else {
      this.activeCategoryObject = categoryObject;
      $(document).trigger('reset.searchResults');
      this.activeCategoryObject.activate();
    }
  };

  Navigation.prototype.deactivateCategory = function () {
    if (this.activeCategoryObject) {
      this.activeCategoryObject.deactivate();
    }
    this.activeCategoryObject = false;
  };

  function NavigationCategory($elem, $nav) {
    this.$category = $elem.parent();
    this.$categoryDropdown = this.$category.find('.js-nav-category-trigger-content');
    this.$nav = $nav;
  }
  NavigationCategory.prototype.activate = function () {
    var self = this;
    var headerBottomPos = 0;
    var hasCategoryDropdown = self.$categoryDropdown.length > 0 ? true : false;
    var $headerMain = self.$nav.closest('.js-site-header-main');
    var $searchCloseIcon = $('.js-search-close-icon-wrapper');

    if (!isMobileWidth) {
      // @todo SDSEARCH-2965 - replace with event triggered from React side for Search GNAV manipulation instead of accessing component directly (such as auto-close)
      var $activeScreenWrapper = $('.js-search-active-screen-wrapper');

      if ($activeScreenWrapper.length) {
        $searchCloseIcon.click();
      }
    }
    if ($headerMain.length) {
      headerBottomPos = $headerMain[0].getBoundingClientRect().bottom;
    }
    self.$categoryDropdown.css({ top: headerBottomPos });
    self.$categoryDropdown.attr('aria-expanded', true).removeClass('hidden');
    self.$category.addClass('active');
    self.$nav.toggleClass('section-dropdown-open', hasCategoryDropdown);
  };
  NavigationCategory.prototype.deactivate = function () {
    this.$categoryDropdown.attr('aria-expanded', false).addClass('hidden');
    this.$category.removeClass('active');
    this.$nav.removeClass('section-dropdown-open');
  };

  Drupal.behaviors.siteHeaderV1 = {
    attached: false,
    attach: function (context) {
      var $module = $('.js-site-header-menu', context);
      var hideHeaderOnScroll = false;
      var navigation;
      var bps = Unison.fetch.all();
      var currentWidth = $(window).width();
      var position = $(window).scrollTop();

      if (!$module.length || this.attached) {
        return;
      }

      this.attached = true;

      hideHeaderOnScroll = $module.hasClass('hide-site-header-on-scroll');
      isMobileWidth = parseInt(currentWidth, 10) < parseInt(bps.large, 10);
      $(window)
        .off('resize.headerCategories')
        .on(
          'resize.headerCategories',
          _.debounce(function () {
            $('body').toggleClass('site-header-menu--opened', false);
            currentWidth = $(window).width();
            isMobileWidth = parseInt(currentWidth, 10) < parseInt(bps.large, 10);
            navigation.deactivateCategory();
          }, 100)
        );

      navigation = new Navigation($module);

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.headerCategories')
          .on(
            'scroll.headerCategories',
            _.debounce(function () {
              var scroll = $(window).scrollTop();

              // If the user scroll down, hide the Nav
              if (scroll <= position) {
                $module.removeClass('site-header-menu--hidden');
              } else if (scroll > 5) {
                $module.addClass('site-header-menu--hidden');
              }
              position = scroll;

              if (!navigation.activeCategoryObject) {
                return;
              }
              navigation.activeCategoryObject.$categoryDropdown.fadeOut(200, function () {
                $(this).attr('style', '');
                navigation.deactivateCategory();
              });
            }, 100)
          );
      }
    }
  };
})(jQuery, window.Drupal);
